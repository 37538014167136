import { Card, Layout, Typography } from "antd";
import React from "react";

const About = () => {
  return (
    <>
      <Card title="About Us" className="w-full">
        <Typography.Title level={2}>About the tool</Typography.Title>
        <p>
          NearScanner is a free to use NFT stats aggregator and alerting system
          primarily based on the Paras marketplace on $NEAR powered by the{" "}
          <a
            href="https://paras.id/collection/boo-monster-by-omarbibznear"
            target="_blank"
            rel="noreferrer"
          >
            Boo Monsters
          </a>
        </p>

        <Typography.Title className="mt-8" level={2}>
          How to use NearScanner
        </Typography.Title>
        <p>
          <b>Viewing collections:</b>
          <br />
          To view the top traded collections on Paras, navigate to the
          Collections tab, you can search through and sort the 300 available
          collections based on the following:
        </p>

        <br />

        <ul className="list-disc pl-4">
          <li>Paras weekly sales ranking</li>
          <li>Floor price</li>
          <li>Average price</li>
          <li>Volume</li>
          <li>Market cap (current FP x cards in circulation)</li>
          <li>Owners</li>
          <li>Number of cards</li>
        </ul>

        <br />

        <p>
          You can also search for a collection in the search bar. If its not
          there, it probably had no trade history in the past week. <br />
          We update the top 300 traded collections regularly, usually under 10
          minutes, you can check when was the last refresh on the top left of
          the table.
        </p>

        <br />

        <p>
          <b>Viewing collection cards (tokens):</b>
          <br />
          Clicking on any of the collections will show you a beta version of our
          analytics. For now we only show a list of cards, their owners and its
          Paras rarity score. Much more is cooking though.
        </p>

        <br />

        <p>
          <b>Creating alerts:</b>
          <br />
          To set alerts, connect your wallet to confirm your NEAR username, join
          the discord to be able to receive DMs (direct messages) from the
          alerting bot. Click on Alerts and set the parameters of the alert
          you’d like. Click activate to turn the alert on. For now you can only
          have 10 alerts that are active at a single time, we aim to provide
          much more flexibility in the near future.
        </p>

        <Typography.Title className="mt-8" level={2}>
          Tracking and Security
        </Typography.Title>
        <p>
          NearScanner uses tracking tools to optimize and monitor the product’s
          performance using privacy friendly tracking such as Plausible
          Analytics.
        </p>
        <p>
          Currently, NearScanner uses Discord to authenticate your login. We
          request the following permissions to login:
        </p>
        <br />
        <ul className="list-disc pl-4">
          <li>Discord username, avatar and banner</li>
        </ul>
        <p className="text-xs">
          You only need to login if you are creating or managing your alerts.
        </p>
        <br />
        <i>
          In the future, NearScanner will offer a NEAR wallet connection to
          authenticate logged in users and to ensure alerts are being fairly
          used cross platform.
        </i>
        <p>
          Always vet tools including NearScanner before you take actions. <br />
          Always review the confirmation page on Discord making sure to review
          integrations permissions before you accept them.
        </p>
      </Card>
      <Layout.Footer
        style={{ textAlign: "center" }}
        className="py-4 md:hidden block"
      >
        NearScanner 2022 - Created with 💜
      </Layout.Footer>
    </>
  );
};

export default About;
