import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { alertsApi } from "../api";
import { Alert } from "../models/alert";

export default function useListAlerts() {
  const [response, setResponse] = useState<{
    data: Alert[];
  }>();
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState<boolean>(true);
  const { isAuthenticated } = useAuth0();

  const call = () => {
    setLoading(true);
    alertsApi
      .fetch()
      .then((response: { data: Alert[] }) => {
        setResponse(response);
      })
      .catch(async (err: any) => {
        setError(err?.response?.data?.error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    isAuthenticated && call();
  }, [isAuthenticated]);

  return { response, error, loading, call };
}
