import { map } from "lodash";
import { divideByToGetNear } from "../../utils/currency";
import useGetCollection from "../useGetCollection";

export const usePriceRaritySnapshot = (id: string) => {
  const { response: collection, loading } = useGetCollection(id!);

  const data = map(collection?.attributes.price_vs_rarity, (item) => ({
    ...item,
    price: parseFloat(item.price) / divideByToGetNear,
    rarity: Math.floor(item.rarity),
  }));

  return { data, loading };
};
