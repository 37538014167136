// import top_collections from "../models/top_collections.json";
import axios from "axios";
import { useEffect, useState } from "react";

export default function useFetchNearRateTime() {
  const [response, setResponse] = useState<{
    prices: [number, number][];
  }>();

  const call = () => {
    axios
      .get(
        "https://api.coingecko.com/api/v3/coins/near/market_chart?vs_currency=usd&days=30&interval=daily"
      )
      .then((res) => setResponse(res.data));
  };

  useEffect(call, []);

  return { response, call };
}
