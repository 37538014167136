import { AutoComplete, Button, Popover } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Search as SearchIcon } from "tabler-icons-react";
import { useDebounce } from "usehooks-ts";
import { secondaryLinkClasses } from "../../constants";
import useListCollections from "../../hooks/useListCollections";
import CollectionItem from "../../pages/Collections/CollectionItem";

const Search = () => {
  const [visible, setVisible] = useState<boolean>(false);
  const [value, setValue] = useState<string>("");
  const debouncedValue = useDebounce<string>(value, 500);
  const { response, call: refetch, loading } = useListCollections();

  const onSearch = (value: string) =>
    value && refetch({ pageNumber: "1", pageSize: "10", query: value });

  const navigate = useNavigate();

  const handleChange = (value: string) => {
    setValue(value);
  };

  const handleSelect = (value: any) => {
    navigate(`/collections/${value}`);
    navigate(0);
  };

  useEffect(() => {
    onSearch(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);
  const SearchInput = (
    <AutoComplete
      bordered={false}
      onSelect={handleSelect}
      onSearch={handleChange}
      placeholder={
        <>
          <SearchIcon className={secondaryLinkClasses} /> Search for a
          collection
        </>
      }
      allowClear
      className="w-72"
      notFoundContent={loading ? "Loading..." : "No results"}
    >
      {value &&
        (response?.data || []).map((collection) => (
          <AutoComplete.Option key={collection.id} value={collection.id}>
            <CollectionItem
              title={collection.attributes.title}
              image={collection.attributes.photo_url}
              allowCopy={false}
            />
          </AutoComplete.Option>
        ))}
    </AutoComplete>
  );

  return (
    <Popover
      content={SearchInput}
      trigger="click"
      visible={visible}
      onVisibleChange={setVisible}
      placement="right"
    >
      <Button
        icon={<SearchIcon className={secondaryLinkClasses} />}
        type="link"
      />
    </Popover>
  );
};

export default Search;
