import { Avatar, message, Skeleton, Tooltip, Typography } from "antd";
import copy from "copy-to-clipboard";
import { truncate } from "lodash";

const CollectionItem = ({
  image,
  size = "default",
  title,
  artist,
  description,
  allowCopy = true,
  loading,
  showDescription = false,
}: {
  image?: string;
  title: string;
  artist?: string;
  size?: "default" | "large" | number;
  description?: string;
  allowCopy?: boolean;
  loading?: boolean;
  showDescription?: boolean;
}) => {
  const handleCopy = () => {
    if (allowCopy) {
      copy(title);
      message.success("Copied to clipboard");
    }
  };

  if (loading)
    return (
      <div className="w-72">
        <Skeleton
          avatar={{ shape: "circle", size }}
          paragraph={false}
          loading={loading}
        />
      </div>
    );

  return (
    <Tooltip
      title={
        description &&
        !showDescription && <span className="text-xs">{description}</span>
      }
    >
      <div className="flex flex-row align-middle gap-3 items-center">
        <div>{image && <Avatar size={size} src={image} />}</div>
        <div onClick={handleCopy}>
          <Typography.Title
            level={5}
            style={{
              color: "#9351D6",
              margin: 0,
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
            className={allowCopy ? "cursor-pointer " : ""}
          >
            {title}
          </Typography.Title>
          {artist && (
            <p className="text-xs break-all ant-typography ant-typography-secondary">
              {truncate(artist, { length: 20 })}
            </p>
          )}
          {showDescription && (
            <Typography.Text type="secondary">{description}</Typography.Text>
          )}
        </div>
      </div>
    </Tooltip>
  );
};

export default CollectionItem;
