import { Form, InputNumber, Modal, Row, Select } from "antd";
import React, { useState } from "react";
import useListCollections from "../../hooks/useListCollections";
import { AlertParams } from "../../models/alert";
import { formatCurrency, numberWithCommas } from "../../utils/currency";
import CollectionItem from "../Collections/CollectionItem";
interface NewAlertProps {
  visible: boolean;
  onCancel: () => void;
  onCreate: (params: AlertParams) => void;
}

const NewAlert: React.FC<NewAlertProps> = ({ visible, onCancel, onCreate }) => {
  const [form] = Form.useForm();
  const [referenceValueDisplay, setReferenceValueDisplay] = useState<string>();
  const [referenceValue, setReferenceValue] = useState<string | number>();
  const [currency, setCurrency] = useState<string>();
  const [prefix, setPrefix] = useState<string>();
  const { response: collections, loading } = useListCollections();

  const handleChange = () => {
    const metric = form.getFieldValue("metric");
    const collection_id = form.getFieldValue("collection_id");
    const trigger = form.getFieldValue("trigger");

    const collection = collections?.data.find(
      (collection) => collection.id === collection_id
    );
    if (metric === "floor_price") {
      setCurrency("Ⓝ");
      setReferenceValue(collection?.attributes.floor_price ?? 0);
      setReferenceValueDisplay(
        formatCurrency(collection?.attributes.floor_price ?? 0)
      );
    }
    if (metric === "avg_price") {
      setCurrency("Ⓝ");
      setReferenceValue(collection?.attributes.avg_price ?? 0);
      setReferenceValueDisplay(
        formatCurrency(collection?.attributes.avg_price ?? 0)
      );
    }
    if (metric === "market_cap") {
      setCurrency("$");
      setReferenceValue(collection?.attributes.volume_usd);
      setReferenceValueDisplay(
        "$" + numberWithCommas(collection?.attributes.volume_usd || "0")
      );
    }

    if (trigger === "value_reached") setPrefix(currency);
    else setPrefix("%");
  };

  return (
    <Modal
      destroyOnClose
      visible={visible}
      title="Create a new alert"
      okText="Create"
      cancelText="Cancel"
      forceRender
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            onCreate({
              data: {
                attributes: {
                  metric: values.metric,
                  trigger: values.trigger,
                  target_value: values.target_value,
                  reference_value: referenceValue ?? 0,
                  alertable_id: values.collection_id,
                  alertable_type: "Collection",
                },
              },
            });
            onCancel();
            form.resetFields();
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{ modifier: "public" }}
        onValuesChange={handleChange}
      >
        <Form.Item
          name="collection_id"
          label="Collection"
          required
          rules={[{ required: true, message: "Select a collection" }]}
        >
          <Select
            showSearch
            loading={loading}
            optionFilterProp="children"
            size="large"
            filterOption={(input: any, option: any) =>
              option &&
              option.children.props.title
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            placeholder="Select a collection"
          >
            {collections?.data.map((collection) => (
              <Select.Option key={collection.id} value={collection.id}>
                <CollectionItem
                  title={collection.attributes.title}
                  image={collection.attributes.photo_url}
                  allowCopy={false}
                />
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="metric"
          label="Metric"
          required
          rules={[{ required: true, message: "Select a metric" }]}
        >
          <Select placeholder="Select a metric">
            <Select.Option value="floor_price">Floor Price Ⓝ</Select.Option>
            <Select.Option value="avg_price">Avg Price Ⓝ </Select.Option>
            <Select.Option value="market_cap">Volume $</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="trigger"
          label="Trigger"
          required
          rules={[{ required: true, message: "Select a trigger" }]}
        >
          <Select placeholder="Select a trigger">
            <Select.Option value="percent_increase">% Increase</Select.Option>
            {form.getFieldValue("metric") !== "market_cap" && (
              <Select.Option value="percent_decrease">% Decrease</Select.Option>
            )}
            {form.getFieldValue("metric") !== "market_cap" && (
              <Select.Option value="percent_change">% Change</Select.Option>
            )}
            <Select.Option value="value_reached">
              {currency} Value Reached
            </Select.Option>
          </Select>
        </Form.Item>
        <Row justify="space-between">
          <span className="text-xs text-gray-500 mb-2">
            Reference Value Now: {referenceValueDisplay}
          </span>
          <Form.Item
            name="target_value"
            label="Value"
            required
            className="w-full"
            rules={[{ required: true, message: "Select a value" }]}
          >
            <InputNumber
              className="w-full"
              placeholder="0.0"
              min={0}
              precision={2}
              step={0.1}
              prefix={prefix}
            />
          </Form.Item>
        </Row>
      </Form>
    </Modal>
  );
};

export default NewAlert;
