export function removePrefix(string: string, prefix: string) {
  if (string.startsWith(prefix)) {
    return string.substring(prefix.length);
  }
  return string;
}

export function removeSuffix(string: string, suffix: string) {
  if (string.endsWith(suffix)) {
    return string.substring(0, string.length - suffix.length);
  }
  return string;
}
