import { Column } from "@ant-design/plots";
import { Card, Col, Row, Table, Tag } from "antd";
import { truncate } from "lodash";
import React from "react";
import { useParams } from "react-router-dom";
import useGetCollection from "../../hooks/useGetCollection";

const Owners: React.FC = () => {
  const { id } = useParams();
  const { response: collection, loading } = useGetCollection(id!);

  const configBar = {
    xField: "no_of_cards",
    yField: "no_of_owned_by",
    columnStyle: {
      fill: "#9351D6",
    },
  };

  const columns = [
    {
      render: (_text: any, _record: any, index: number) => index + 1,
      width: "4ch",
    },
    {
      title: "Owner",
      dataIndex: "owner_id",
      render: (owner_id: string) =>
        owner_id && (
          <a
            href={`https://paras.id/${owner_id}/collectibles`}
            target="_blank"
            rel="noreferrer"
          >
            <Tag color="purple" className="cursor-pointer">
              {owner_id.endsWith(".near")
                ? truncate(owner_id, { length: 25 })
                : truncate(owner_id, { length: 10 })}
            </Tag>
          </a>
        ),
    },
    {
      title: "# of Tokens",
      dataIndex: "no_of_cards",
    },
  ];

  return (
    <Row className="" justify="space-between" gutter={[10, 10]}>
      {collection?.attributes.cards_per_owner && (
        <Col xl={12} xs={24}>
          <Card loading={loading} title="Cards Per Owner">
            <>
              <span className="text-xs text-gray-500"># of Owners</span>
              <Column
                {...configBar}
                data={collection.attributes.cards_per_owner.map((o) => ({
                  no_of_cards: o.no_of_cards.toString(),
                  no_of_owned_by: o.no_of_owned_by,
                }))}
              />
              <div className="w-full flex justify-center">
                <span className="text-xs text-gray-500"># of Tokens</span>
              </div>
            </>
          </Card>
        </Col>
      )}

      <Col xl={12} xs={24}>
        <Card loading={loading} title="Top Owners" className="w-full">
          <Table
            rowKey="owner_id"
            pagination={{ pageSize: 6 }}
            columns={columns}
            loading={loading}
            dataSource={collection?.attributes.top_owners}
          />
        </Card>
      </Col>
    </Row>
  );
};

export default Owners;
