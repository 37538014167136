import { AlertOutlined } from "@ant-design/icons";
import { Avatar, Card, Spin, Table, Typography } from "antd";
import { capitalCase } from "capital-case";
import { map } from "lodash";

const Rarity = () => {
  const data = {
    accessories: {
      items: [
        {
          name: "Twins",
          rank: "1",
          percentage: "0.003",
          photo_url: "https://via.placeholder.com/150/",
        },
        {
          name: "Candy Cane",
          rank: "2",
          percentage: "0.02",
          photo_url: "https://via.placeholder.com/150/",
        },
      ],
    },
    backgrounds: {
      items: [
        {
          name: "Twins",
          rank: "1",
          percentage: "0.003",
          photo_url: "https://via.placeholder.com/150/",
        },
        {
          name: "Candy Cane",
          rank: "2",
          percentage: "0.02",
          photo_url: "https://via.placeholder.com/150/",
        },
      ],
    },
    clothes: {
      items: [
        {
          name: "Twins",
          rank: "1",
          percentage: "0.003",
          photo_url: "https://via.placeholder.com/150/",
        },
        {
          name: "Candy Cane",
          rank: "2",
          percentage: "0.02",
          photo_url: "https://via.placeholder.com/150/",
        },
      ],
    },
  };

  const columns = [
    {
      title: "Image",
      dataIndex: "photo_url",
      key: "photo_url",
      render: (photo_url: string) => <Avatar size="large" src={photo_url} />,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Rank",
      dataIndex: "rank",
      key: "rank",
    },
    {
      title: "Percentage",
      dataIndex: "percentage",
      key: "percentage",
    },
  ];

  return (
    <Spin
      spinning={true}
      indicator={<AlertOutlined />}
      tip={<Typography.Title level={3}>Coming Soon..</Typography.Title>}
    >
      {map(data, (key, value) => (
        <>
          <Typography.Title level={5}>{capitalCase(value)}</Typography.Title>
          <Card bodyStyle={{ padding: "0.1rem" }}>
            <Table
              showSorterTooltip={false}
              dataSource={key.items}
              columns={columns}
              scroll={{ x: 400 }}
              pagination={{ position: ["bottomCenter"] }}
            />
          </Card>
        </>
      ))}
    </Spin>
  );
};

export default Rarity;
