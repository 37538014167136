import { useState } from "react";
import { useEffectOnce } from "usehooks-ts";
import { collectionsApi } from "../api";
import { Collection } from "../models/collection";

export default function useGetCollection(id: string) {
  const [response, setResponse] = useState<Collection>();
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState<boolean>(true);

  const call = () => {
    setLoading(true);
    collectionsApi
      .get(id)
      .then((response) => {
        setResponse(response.data);
      })
      .catch(async (err) => {
        setError(err?.response?.data?.error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffectOnce(call);

  return { response, error, loading, call };
}
