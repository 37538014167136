import { FireFilled, InfoCircleOutlined } from "@ant-design/icons";
import {
  Avatar,
  Button,
  Card,
  Col,
  Image,
  message,
  PageHeader,
  Popover,
  Row,
  Spin,
  Table,
  Tooltip,
  Typography,
} from "antd";
import { take } from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Refresh } from "tabler-icons-react";
import { secondaryLinkClasses } from "../../constants";
import useListCollections from "../../hooks/useListCollections";
import { Collection } from "../../models/collection";
import { formatCurrency, numberWithCommas } from "../../utils/currency";
import { removeSuffix } from "../../utils/string";
import CollectionItem from "./CollectionItem";
import LiveTrackers from "./LiveTrackers";
import Snapshot from "./Snapshot";

const Collections = () => {
  const navigate = useNavigate();

  const { response, loading, call: refetch } = useListCollections();
  const [sort, setSort] = useState<string>("");

  const columns: any[] = [
    {
      dataIndex: ["attributes", "rank"],
      key: "rank",
      width: 100,
      fixed: "left",
      render: (id: string, record: Collection) => (
        <div className="w-20 flex justify-around items-center">
          {id && <b className="text-bold">{id}</b>}
          <Popover
            content={
              <Image
                src={record.attributes.photo_url + "?w=200"}
                width={200}
                preview={false}
                placeholder={<Spin spinning />}
              />
            }
          >
            <Avatar
              size="large"
              className="hover:scale-150"
              src={record.attributes.photo_url + "?w=100"}
            />
          </Popover>
        </div>
      ),
      sorter: (a: Collection, b: Collection) =>
        a.attributes.rank - b.attributes.rank,
    },
    {
      title: "Collection",
      dataIndex: ["attributes", "photo_url"],
      key: "photo_url",
      width: 350,
      render: (_image: any, record: Collection) => (
        <CollectionItem
          size="large"
          title={record.attributes.title}
          artist={record.attributes.artist}
          description={record.attributes.description}
          allowCopy={false}
        />
      ),
    },
    {
      title: () => (
        <Tooltip
          placement="bottom"
          title="Lowest card available for purchase for a collection. USD exchange: real-time."
        >
          Floor Price <InfoCircleOutlined />
        </Tooltip>
      ),
      dataIndex: ["attributes", "floor_price"],
      key: "floor_price",

      sorter: true,
      render: (text: number, record: Collection) => (
        <>
          {formatCurrency(text)}
          {record.attributes.floor_price_usd && (
            <>
              <br />
              <Typography.Text type="secondary" className="text-xs">
                {`$${numberWithCommas(record.attributes.floor_price_usd)}`}
              </Typography.Text>
            </>
          )}
        </>
      ),
    },
    {
      title: () => (
        <Tooltip
          placement="bottom"
          title="The average selling price for this collection (covers all previous sales). USD exchange: real-time."
        >
          Avg. Price <InfoCircleOutlined />
        </Tooltip>
      ),
      dataIndex: ["attributes", "avg_price"],
      key: "avg_price",

      sorter: true,
      render: (text: number, record: Collection) => (
        <>
          {formatCurrency(text)}
          <br />
          <Typography.Text
            type="secondary"
            className="text-xs"
          >{`$${numberWithCommas(
            record.attributes.avg_price_usd
          )}`}</Typography.Text>
        </>
      ),
    },
    {
      title: () => (
        <Tooltip
          placement="bottom"
          title="Total actual sales generated by card transactions in NEAR. USD exchange: calculated at the time of each sale and compounded."
        >
          Volume <InfoCircleOutlined />
        </Tooltip>
      ),
      dataIndex: ["attributes", "volume_usd"],
      key: "volume_usd",

      sorter: true,
      render: (text: string, record: Collection) => (
        <>
          {`$${numberWithCommas(text, 0)}`}
          <br />
          <Typography.Text type="secondary" className="text-xs">
            {formatCurrency(record.attributes.volume, "Ⓝ", 0)}
          </Typography.Text>
        </>
      ),
    },
    {
      title: () => (
        <Tooltip
          title="Current floor price x total cards in circulation. USD exchange: real-time."
          placement="bottom"
        >
          Market Cap <InfoCircleOutlined />
        </Tooltip>
      ),
      key: "market_cap",

      sorter: (a: Collection, b: Collection) =>
        a.attributes.floor_price * a.attributes.total_cards -
        b.attributes.floor_price * b.attributes.total_cards,
      render: (_: string, record: Collection) => (
        <>
          {"$" +
            numberWithCommas(
              (
                parseInt(record.attributes.floor_price_usd) *
                record.attributes.total_cards
              ).toString(),
              0
            )}
          <br />
          <Typography.Text type="secondary" className="text-xs">
            {formatCurrency(
              record.attributes.floor_price * record.attributes.total_cards,
              "Ⓝ",
              0
            )}
          </Typography.Text>
        </>
      ),
    },
    {
      title: () => (
        <Tooltip
          placement="bottom"
          title="Total number of unique owners for a collection."
        >
          Owners <InfoCircleOutlined />
        </Tooltip>
      ),
      dataIndex: ["attributes", "total_owners"],
      key: "total_owners",

      sorter: true,
      render: (text: any) => numberWithCommas(text, 0),
    },
    {
      title: () => (
        <Tooltip
          placement="bottom"
          title="Total number of cards that have been minted. Includes listed and unlisted cards."
        >
          Cards <InfoCircleOutlined />
        </Tooltip>
      ),
      dataIndex: ["attributes", "total_cards"],
      key: "total_cards",
      sorter: true,

      render: (text: any, record: Collection) => (
        <Tooltip title="Total number of cards that have been minted. Includes listed and unlisted cards.">
          {numberWithCommas(text, 0)}
          <br />
          <Typography.Text type="secondary" className="text-xs">
            On Sale: {record.attributes.total_cards_on_sale}
          </Typography.Text>
        </Tooltip>
      ),
    },
  ];

  const handleChange = (_pagination: any, _filters: any, sorter: any) => {
    if (sorter.hasOwnProperty("column"))
      setSort(`${sorter.field[1]} ${removeSuffix(sorter.order, "end")}`);
  };

  useEffect(() => {
    sort && refetch({ pageNumber: "1", pageSize: "300", sort });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sort]);

  return (
    <>
      <LiveTrackers />
      <Snapshot collections={take(response?.data, 4)} />
      <PageHeader
        style={{ paddingRight: 0, paddingLeft: 0 }}
        title={
          <Col>
            <FireFilled
              style={{ color: "#9351D6", verticalAlign: "baseline" }}
            />{" "}
            Top Collections
            <Row align="middle" className="text-xs">
              <Button
                type="link"
                className={secondaryLinkClasses}
                icon={<Refresh className="text-xs" />}
                onClick={() => {
                  refetch({ pageNumber: "1", pageSize: "300" });
                  message.success("Refreshed!");
                }}
              />
              <Typography.Text type="secondary">
                Updated {moment(response?.meta.updated_at).fromNow()}
              </Typography.Text>
            </Row>
          </Col>
        }
      />
      <Card bodyStyle={{ padding: "0.1rem" }}>
        <Table
          showSorterTooltip={false}
          dataSource={response?.data}
          loading={loading}
          columns={columns}
          rowKey="id"
          scroll={{ x: 1300 }}
          onChange={handleChange}
          sortDirections={["descend", "ascend", "descend"]}
          rowClassName="cursor-pointer"
          size="small"
          pagination={{ position: ["bottomCenter"] }}
          onRow={(record: Collection) => {
            return {
              onClick: () => {
                navigate(`/collections/${record.id}/cards`);
              },
            };
          }}
        />
      </Card>
    </>
  );
};

export default Collections;
