import { LoginOutlined, LogoutOutlined, UserOutlined } from "@ant-design/icons";
import { useAuth0 } from "@auth0/auth0-react";
import { Avatar, Col, Dropdown, Layout, Menu, Row, Switch } from "antd";
import { Popover } from "antd-mobile";
import { Action } from "antd-mobile/es/components/popover";
import { useContext, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  AlertCircle,
  Bulb,
  BulbOff,
  Menu2,
  Speakerphone,
  Table,
} from "tabler-icons-react";
import { ThemeContext } from "../../context/ThemeContext";
import Logo from "../Logo";
import Search from "../Search";

const Header = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { loginWithRedirect, logout, isAuthenticated, user, getIdTokenClaims } =
    useAuth0();
  const { toggle, toggleFunction } = useContext(ThemeContext);
  useEffect(() => {
    isAuthenticated &&
      getIdTokenClaims()
        .then(
          (token) => token?.__raw && localStorage.setItem("token", token?.__raw)
        )
        .catch((err) => console.log(err));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  const selectedKeys = () => {
    if (location.pathname.includes("/collection")) {
      return ["/"];
    }
    return [location.pathname];
  };
  const actions: Action[] = [
    { key: "/", icon: <Table />, text: "Collections" },
    { key: "/alerts", icon: <Speakerphone />, text: "Alerts" },
    { key: "/about", icon: <AlertCircle />, text: "About" },
  ];

  return (
    <Layout.Header
      className="px-4 md:px-12 shadow-lg"
      style={{ background: "none" }}
    >
      <Row align="middle" justify="space-between">
        <Col>
          <Row align="middle" className="gap-2">
            <Col className="md:block hidden">
              <Link to="/">
                <Logo />
              </Link>
            </Col>
            <Col className="block md:hidden">
              <Popover.Menu
                mode="dark"
                actions={actions}
                placement="bottom-start"
                onAction={(node: Action) => navigate(node.key as string)}
                trigger="click"
              >
                <>
                  <Logo />
                  <Menu2 className="text-paras-purple ml-2" />
                </>
              </Popover.Menu>
            </Col>
            <Col className="flex">
              <Search />
            </Col>
          </Row>
        </Col>
        <Col className="flex items-center grow gap-2 justify-end">
          <div className="hidden md:flex grow">
            <Menu
              style={{ background: "transparent" }}
              className="font-semibold w-full justify-end"
              mode="horizontal"
              selectedKeys={selectedKeys()}
            >
              <Menu.Item key="/">
                <Link to="/" className="text-sm md:text-base">
                  <Table /> Collections
                </Link>
              </Menu.Item>
              <Menu.Item key="/alerts">
                <Link to="/alerts" className="text-sm md:text-base">
                  <Speakerphone /> Alerts
                </Link>
              </Menu.Item>
              <Menu.Item key="/about">
                <Link to="/about" className="text-sm md:text-base">
                  <AlertCircle /> About
                </Link>
              </Menu.Item>
            </Menu>
          </div>

          <Dropdown
            placement="bottomRight"
            arrow
            trigger={["click", "hover"]}
            overlay={
              <Menu>
                {!isAuthenticated && (
                  <Menu.Item
                    key="login"
                    onClick={() => loginWithRedirect()}
                    icon={<LoginOutlined />}
                  >
                    Login
                  </Menu.Item>
                )}
                {isAuthenticated && (
                  <Menu.Item
                    icon={<LogoutOutlined />}
                    key="logout"
                    onClick={() => {
                      localStorage.removeItem("token");
                      logout({ returnTo: window.location.origin });
                    }}
                  >
                    Logout
                  </Menu.Item>
                )}
              </Menu>
            }
          >
            <Avatar
              className="flex cursor-pointer"
              src={user?.picture}
              icon={<UserOutlined />}
            />
          </Dropdown>

          <Switch
            checkedChildren={<BulbOff size={14} />}
            unCheckedChildren={<Bulb size={14} />}
            checked={toggle}
            onClick={toggleFunction}
          />
        </Col>
      </Row>
    </Layout.Header>
  );
};

export default Header;
