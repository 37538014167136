import { message } from "antd";
import { useState } from "react";
import { alertsApi } from "../api";

export default function useDeleteAlert() {
  const [response, setResponse] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);

  const call = (id: string) => {
    setLoading(true);
    setResponse(false);
    alertsApi
      .destroy(id)
      .then(() => {
        message.success("Alert deleted successfully");
      })
      .catch(async (err: any) => {
        setError(err?.response?.data?.error);
      })
      .finally(() => {
        setLoading(false);
        setResponse(true);
      });
  };

  return { response, error, loading, call };
}
