import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { alertsApi } from "../api";

export default function useHasJoinedServer() {
  const [response, setResponse] = useState<{
    has_joined_server: boolean;
    invite_url: string;
  }>();
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState<boolean>(true);
  const { isAuthenticated } = useAuth0();

  const call = () => {
    setLoading(true);
    alertsApi
      .hasJoinedServer()
      .then((response) => {
        setResponse(response);
      })
      .catch(async (err) => {
        setError(err?.response?.data?.error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    isAuthenticated && call();
  }, [isAuthenticated]);

  return { response, error, loading, call };
}
