import { Line } from "@ant-design/plots";
import { Card, Col, Row } from "antd";
import { map } from "lodash";
import useFetchNearRateTime from "../../hooks/useFetchNearRateTime";

const LiveTrackers = () => {
  const { response } = useFetchNearRateTime();
  const nearTime = map(response?.prices, (t) => ({ time: t[0], price: t[1] }));

  const config = {
    animation: undefined,
    xField: "time",
    yField: "price",
    height: 250,
    theme: {
      defaultColor: "#9351D6",
    },
    lineStyle: {
      stroke: "#9351D6",
    },
  };

  return (
    <Card style={{ marginBottom: "2rem" }}>
      <Row className="w-full" justify="space-between">
        <Col className="w-full flex flex-col">
          <Line
            {...config}
            data={nearTime}
            xAxis={{ tickCount: 0 }}
            yAxis={{ label: { formatter: (c) => c + " Ⓝ" } }}
          />
          <span className="text-xs text-gray-500 self-center">
            Last 30 Days
          </span>
        </Col>
      </Row>
    </Card>
  );
};

export default LiveTrackers;
