export const divideByToGetNear = 1000000000000000000000000;

export const formatCurrency = (value: number, symbol = "Ⓝ", precision = 2) =>
  numberWithCommas(
    (value / divideByToGetNear).toFixed(precision).toString(),
    precision
  ) + symbol;
export const formatCurrencyNumber = (value: number, precision = 2) =>
  parseFloat((value / divideByToGetNear).toFixed(precision));
export const numberWithCommas = (n: string, precision = 2) =>
  parseFloat(n)
    .toFixed(precision)
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
