import { PageHeader, Tabs } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import useGetCollection from "../../hooks/useGetCollection";
import CollectionItem from "../Collections/CollectionItem";
import Analytics from "./Analytics";
import Cards from "./Cards";
import Rarity from "./Rarity";
import Stats from "./Stats";

const CollectionDetails = () => {
  const navigate = useNavigate();
  const { id, tab } = useParams();
  const { response: collection, loading } = useGetCollection(id!);

  const handleOnClick = (key: string) => navigate(`/collections/${id}/${key}`);

  return (
    <>
      <PageHeader
        onBack={() => navigate("/")}
        title={
          <CollectionItem
            loading={loading}
            image={collection?.attributes.photo_url}
            size="large"
            title={collection?.attributes.title || "Loading..."}
            artist={collection?.attributes.artist}
            description={collection?.attributes.description}
          />
        }
      />

      <Tabs
        defaultActiveKey={tab || "cards"}
        centered
        onTabClick={handleOnClick}
      >
        <Tabs.TabPane tab={<span className="text-lg">Cards</span>} key="cards">
          {collection && <Cards collection={collection} />}
        </Tabs.TabPane>
        <Tabs.TabPane tab={<span className="text-lg">Stats</span>} key="stats">
          <Stats />
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={<span className="text-lg">Analytics</span>}
          key="analytics"
        >
          <Analytics />
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={<span className="text-lg">Rarity</span>}
          key="rarity"
        >
          <Rarity />
        </Tabs.TabPane>
      </Tabs>
    </>
  );
};

export default CollectionDetails;
