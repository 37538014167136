import {
  AlertFilled,
  DeleteOutlined,
  LoginOutlined,
  PauseCircleOutlined,
  PlayCircleOutlined,
  PlusOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Alert as AntAlert,
  Button,
  Card,
  Modal,
  PageHeader,
  Popconfirm,
  Space,
  Table,
  Tag,
} from "antd";
import { capitalCase } from "capital-case";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { secondaryButtonClasses, secondaryLinkClasses } from "../../constants";
import useCreateAlert from "../../hooks/useCreateAlert";
import useDeleteAlert from "../../hooks/useDeleteAlert";
import useHasJoinedServer from "../../hooks/useHasJoinedServer";
import useListAlerts from "../../hooks/useListAlerts";
import useUpdateAlert from "../../hooks/useUpdateAlert";
import { Alert } from "../../models/alert";
import { numberWithCommas } from "../../utils/currency";
import CollectionItem from "../Collections/CollectionItem";
import NewAlert from "./NewAlert";

const Alerts = () => {
  const [showAddModal, setShowAddModal] = useState<boolean>(false);
  const navigate = useNavigate();
  const handleOpenModal = () => setShowAddModal(true);
  const handleCloseModal = () => setShowAddModal(false);
  const { isAuthenticated, loginWithRedirect, isLoading } = useAuth0();
  const { response, call, loading: listLoading } = useListAlerts();
  const { response: hasJoinedServerResponse, loading: joinedServerLoading } =
    useHasJoinedServer();

  const { call: update, loading: updateLoading } = useUpdateAlert();
  const { call: destroy, loading: destroyLoading } = useDeleteAlert();
  const { call: create, loading: createLoading } = useCreateAlert();

  const statusColor: Record<string, string> = {
    active: "green",
    successful: "#9351D6",
    paused: "#ED9B59",
  };

  const loading =
    updateLoading ||
    destroyLoading ||
    createLoading ||
    listLoading ||
    joinedServerLoading;

  const actionButtonIcon = (record: Alert) => {
    if (record.attributes.status === "active") return <PauseCircleOutlined />;
    if (record.attributes.status === "paused") return <PlayCircleOutlined />;
    if (record.attributes.status === "successful") return <ReloadOutlined />;
  };

  const actionButtonTitle = (record: Alert) => {
    if (record.attributes.status === "active") return "Pause";
    if (record.attributes.status === "paused") return "Resume";
    if (record.attributes.status === "successful") return "Retry";
  };

  const handleAction = (record: Alert) => {
    if (record.attributes.status === "active")
      return update(record.id, "paused");
    if (record.attributes.status === "paused")
      return update(record.id, "active");
    if (record.attributes.status === "successful")
      return update(record.id, "active");
  };

  const columns: any[] = [
    {
      title: "#",
      width: 30,
      key: "index",
      render: (_text: any, _record: any, index: number) => index + 1,
    },
    {
      title: "Collection",
      dataIndex: ["attributes", "alertable_id"],
      width: 400,
      render: (id: string, record: Alert) => {
        return (
          <CollectionItem
            image={record.attributes.alertable_photo_url}
            size="large"
            title={record.attributes.alertable_title}
            allowCopy={false}
          />
        );
      },
    },
    {
      title: "Metric",
      dataIndex: ["attributes", "metric"],
      render: (value: string) => capitalCase(value),
    },
    {
      title: "Trigger",
      dataIndex: ["attributes", "trigger"],
      render: (value: string) => capitalCase(value),
    },
    {
      title: "Target Value",
      dataIndex: ["attributes", "target_value"],
      render: (_: any, record: Alert) =>
        record.attributes.trigger === "value_reached"
          ? `${
              record.attributes.metric === "market_cap" ? "$" : "Ⓝ"
            }${numberWithCommas(record.attributes.target_value + "")}`
          : `${record.attributes.target_value}%`,
    },
    {
      title: "Status",
      dataIndex: ["attributes", "status"],
      render: (text: string) => (
        <Tag color={statusColor[text]}>{capitalCase(text)}</Tag>
      ),
    },
    {
      title: "Action",
      key: "action",
      fixed: "right",
      width: 220,
      render: (record: Alert) => (
        <Space size="small">
          <Button
            type="link"
            className={secondaryLinkClasses}
            icon={actionButtonIcon(record)}
            onClick={() => handleAction(record)}
          >
            {actionButtonTitle(record)}
          </Button>
          <Popconfirm
            title="Are you sure you want to delete alert?"
            okText="Yes"
            cancelText="No"
            onConfirm={() => destroy(record.id)}
          >
            <Button
              type="link"
              className={secondaryLinkClasses}
              icon={<DeleteOutlined />}
            >
              Delete
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    if (!loading) call();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createLoading, updateLoading, destroyLoading]);

  return (
    <>
      <AntAlert
        className="mb-4"
        showIcon
        type="error"
        message="Make sure to unblock the bot from sending you DMs: Check your discord settings → privacy & safety → allow DMs from servers"
      />
      {!hasJoinedServerResponse?.has_joined_server &&
        isAuthenticated &&
        !loading && (
          <AntAlert
            showIcon
            message={
              <>
                <span>
                  You need to join the Near Scanner Discord to receive alerts.
                </span>{" "}
                &nbsp;
                <Button
                  type="link"
                  href={hasJoinedServerResponse?.invite_url}
                  target="_blank"
                  className="p-0"
                >
                  Click here to join.
                </Button>
              </>
            }
          />
        )}
      <PageHeader
        title="Alerts"
        subTitle={<Tag color="purple">{response?.data.length}/10</Tag>}
        extra={
          <Button
            icon={<PlusOutlined />}
            onClick={handleOpenModal}
            type="default"
            className={secondaryButtonClasses}
            disabled={
              (response?.data && response.data.length >= 10) ||
              !hasJoinedServerResponse?.has_joined_server
            }
          >
            New Alert
          </Button>
        }
      />
      <Card bodyStyle={{ padding: "0.1rem" }}>
        <Table
          showSorterTooltip={false}
          pagination={{ position: ["bottomCenter"] }}
          columns={columns}
          dataSource={response?.data}
          rowKey="id"
          scroll={{ x: 1200 }}
          loading={loading && isAuthenticated}
        />
      </Card>

      <Modal
        visible={!isAuthenticated && !isLoading}
        onCancel={() => navigate(-1)}
        title={
          <>
            <AlertFilled className="text-2xl" /> Login
          </>
        }
        footer={<></>}
      >
        <div className="flex flex-col items-center gap-4">
          <span>Please login to start adding alerts</span>
          <Button
            key="login"
            type="primary"
            onClick={() => loginWithRedirect()}
            icon={<LoginOutlined />}
          >
            Login
          </Button>
        </div>
      </Modal>
      <NewAlert
        visible={showAddModal}
        onCancel={handleCloseModal}
        onCreate={create}
      />
    </>
  );
};

export default Alerts;
