import { Card, Carousel, Typography } from "antd";
import { isEmpty, map, truncate } from "lodash";
import { Trophy } from "tabler-icons-react";
import { Collection } from "../../models/collection";
import { formatCurrency, numberWithCommas } from "../../utils/currency";
import CollectionItem from "./CollectionItem";
export const Snapshot = ({ collections }: { collections: Collection[] }) => {
  return (
    <Card
      loading={isEmpty(collections)}
      className="md:block hidden"
      title={
        <div className="flex gap-2">
          <Trophy className="text-paras-purple" />
          <Typography.Title level={4}>Collection Highlights</Typography.Title>
        </div>
      }
    >
      <Carousel autoplay dotPosition="right">
        {map(collections, (collection) => (
          <div className="h-72" key={collection.id}>
            <div className="flex flex-col sm:flex-row items-center justify-between h-full gap-10">
              <div className="flex-1">
                <CollectionItem
                  key={collection.id}
                  showDescription
                  allowCopy={false}
                  size={100}
                  description={truncate(collection.attributes.description, {
                    length: 150,
                  })}
                  artist={collection.attributes.artist}
                  image={collection.attributes.photo_url}
                  title={collection.attributes.title}
                />
              </div>
              <div className="w-1/2 md:w-2/3 flex justify-end">
                <div className="flex flex-row px-10 gap-6 text-center overflow-x-scroll no-scrollbar">
                  <Card title="Floor Price" className="min-w-[11rem]">
                    <div className="flex flex-col justify-center items-center align-middle">
                      <Typography.Title level={4}>
                        {formatCurrency(collection.attributes.floor_price)}
                      </Typography.Title>
                      <Typography.Text type="secondary" className="text-xs">
                        {`$${numberWithCommas(
                          collection.attributes.floor_price_usd
                        )}`}
                      </Typography.Text>
                    </div>
                  </Card>
                  <Card title="Avg. Price" className="min-w-[11rem]">
                    <div className="flex flex-col justify-center items-center align-middle">
                      <Typography.Title level={4}>
                        {formatCurrency(collection.attributes.avg_price)}
                      </Typography.Title>
                      <Typography.Text type="secondary" className="text-xs">
                        {`$${numberWithCommas(
                          collection.attributes.avg_price_usd
                        )}`}
                      </Typography.Text>
                    </div>
                  </Card>
                  <Card title="Volume" className="min-w-[11rem]">
                    <div className="flex flex-col justify-center items-center align-middle">
                      <Typography.Title level={4}>
                        {`$${numberWithCommas(
                          collection.attributes.volume_usd
                        )}`}
                      </Typography.Title>
                      <Typography.Text type="secondary" className="text-xs">
                        {formatCurrency(collection.attributes.volume)}
                      </Typography.Text>
                    </div>
                  </Card>
                  <Card title="Market Cap" className="min-w-[11rem]">
                    <div className="flex flex-col justify-center items-center align-middle">
                      <Typography.Title level={4}>
                        {"$" +
                          numberWithCommas(
                            (
                              parseInt(collection.attributes.floor_price_usd) *
                              collection.attributes.total_cards
                            ).toString(),
                            0
                          )}
                      </Typography.Title>
                      <Typography.Text type="secondary" className="text-xs">
                        {formatCurrency(
                          collection.attributes.floor_price *
                            collection.attributes.total_cards,
                          "Ⓝ",
                          0
                        )}
                      </Typography.Text>
                    </div>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Carousel>
    </Card>
  );
};

export default Snapshot;
