import { useEffect, useState } from "react";
import { collectionsApi } from "../api";
import { Collection, CollectionMeta } from "../models/collection";

export default function useListCollections() {
  const [response, setResponse] = useState<{
    data: Collection[];
    meta: CollectionMeta;
  }>();
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState<boolean>(true);

  const call = ({
    pageNumber,
    pageSize,
    query,
    sort,
  }: {
    pageNumber: string;
    pageSize: string;
    query?: string;
    sort?: string;
  }) => {
    setLoading(true);
    collectionsApi
      .fetch({ pageNumber, pageSize, query, sort })
      .then((response) => {
        setResponse(response);
      })
      .catch(async (err) => {
        setError(err?.response?.data?.error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    call({ pageNumber: "1", pageSize: "300" });
  }, []);

  return { response, error, loading, call };
}
