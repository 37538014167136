/* eslint-disable jsx-a11y/anchor-is-valid */
import { Layout } from "antd";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import "./App.less";
import Footer from "./components/Footer";
import Header from "./components/Header";
import { ThemeProvider } from "./context/ThemeContext";
import About from "./pages/About";
import Alerts from "./pages/Alerts";
import CollectionDetails from "./pages/CollectionDetails";
import Collections from "./pages/Collections";

const { Content } = Layout;

function App() {
  return (
    <BrowserRouter>
      <Layout>
        <ThemeProvider>
          <Header />
          <Content className="p-4 md:p-12 main-content">
            <Routes>
              <Route path="/" element={<Collections key={Date.now()} />} />
              <Route path="collections/:id">
                <Route path=":tab" element={<CollectionDetails />} />
                <Route path="" element={<CollectionDetails />} />
              </Route>
              <Route path="alerts" element={<Alerts />} />
              <Route path="about" element={<About />} />
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
            <Footer />
          </Content>
        </ThemeProvider>
      </Layout>
    </BrowserRouter>
  );
}

export default App;
