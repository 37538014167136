import { Interval } from "../models/snapshot";

export const secondaryButtonClasses =
  "text-paras-orange hover:text-paras-orange hover:border-paras-orange focus:text-paras-orange focus:border-paras-orange";
export const secondaryLinkClasses =
  "text-paras-orange hover:text-paras-orange focus:text-paras-orange visited:text-paras-orange active:text-paras-orange";
export const INTERVAL_MAP: Readonly<Record<Interval, string>> = {
  "24hours": "24H",
  "7days": "7D",
  "30days": "30D",
};
