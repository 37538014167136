import { Col, Descriptions, Row, Spin, Typography } from "antd";
import { abbreviateNumber } from "js-abbreviation-number";
import { useParams } from "react-router-dom";
import useGetCollection from "../../hooks/useGetCollection";
import { formatCurrency } from "../../utils/currency";

const Stats = () => {
  const { id } = useParams();
  const { response: collection, loading } = useGetCollection(id!);
  return (
    <Spin spinning={loading}>
      <Descriptions
        bordered
        size="small"
        labelStyle={{ fontWeight: "bold" }}
        className="pb-4"
      >
        <Descriptions.Item span={3} label="Floor Price">
          <Row className="w-full" align="middle" justify="space-between">
            <Col>
              {collection?.attributes.floor_price &&
                formatCurrency(collection?.attributes.floor_price)}
              <br />
              <Typography.Text type="secondary" className="text-xs">
                {collection?.attributes.floor_price_usd &&
                  `$${abbreviateNumber(
                    parseInt(collection?.attributes.floor_price_usd),
                    0
                  )}`}
              </Typography.Text>
            </Col>
            {/* <Statistic
              value={11.28}
              precision={2}
              valueStyle={{ color: "#3f8600", fontSize: "1rem" }}
              prefix={<ArrowUpOutlined />}
              suffix="%"
            /> */}
          </Row>
        </Descriptions.Item>
        <Descriptions.Item span={3} label="Avg. Price">
          <Row className="w-full" align="middle" justify="space-between">
            <Col>
              {collection?.attributes.avg_price &&
                formatCurrency(collection?.attributes.avg_price)}
              <br />
              <Typography.Text type="secondary" className="text-xs">
                {collection &&
                  `$${abbreviateNumber(
                    parseInt(collection?.attributes.avg_price_usd),
                    0
                  )}`}
              </Typography.Text>
            </Col>
            {/* <Statistic
              value={11.28}
              precision={2}
              valueStyle={{ color: "#3f8600", fontSize: "1rem" }}
              prefix={<ArrowUpOutlined />}
              suffix="%"
            /> */}
          </Row>
        </Descriptions.Item>
        <Descriptions.Item span={3} label="Volume">
          <Row className="w-full" align="middle" justify="space-between">
            <Col>
              {collection?.attributes.volume &&
                formatCurrency(collection?.attributes.volume)}
              <br />
              <Typography.Text type="secondary" className="text-xs">
                {collection &&
                  `$${abbreviateNumber(
                    parseInt(collection?.attributes.volume_usd),
                    0
                  )}`}
              </Typography.Text>
            </Col>
            {/* <Statistic
              value={12.28}
              precision={2}
              valueStyle={{ color: "#cf1322", fontSize: "1rem" }}
              prefix={<ArrowDownOutlined />}
              suffix="%"
            /> */}
          </Row>
        </Descriptions.Item>
        <Descriptions.Item span={3} label="Market Cap">
          <Row className="w-full" align="middle" justify="space-between">
            <Col>
              {collection &&
                formatCurrency(
                  collection?.attributes.floor_price *
                    collection.attributes.total_cards
                )}
              <br />
              <Typography.Text type="secondary" className="text-xs">
                {collection &&
                  `$${abbreviateNumber(
                    parseInt(collection?.attributes.floor_price_usd) *
                      collection.attributes.total_cards,
                    0
                  )}`}
              </Typography.Text>
            </Col>
            {/* <Statistic
              value={5.28}
              precision={2}
              valueStyle={{ color: "#cf1322", fontSize: "1rem" }}
              prefix={<ArrowDownOutlined />}
              suffix="%"
            /> */}
          </Row>
        </Descriptions.Item>
      </Descriptions>
    </Spin>
  );
};

export default Stats;
