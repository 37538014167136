import { Layout } from "antd";

const Footer = () => {
  return (
    <Layout.Footer style={{ textAlign: "center" }} className="md:py-4 py-0">
      <span className="hidden md:block">
        NearScanner 2022 - Created with 💜
      </span>
    </Layout.Footer>
  );
};

export default Footer;
