import { Spin } from "antd";
import React, { Suspense } from "react";
// Context has been created
const ThemeContext = React.createContext({
  toggle: true,
  toggleFunction: () => {},
});
// Provider
const ThemeProvider = ({ children }: { children: React.ReactNode }) => {
  const [toggle, setToggle] = React.useState(
    window.localStorage.getItem("theme") === "true"
  );
  const toggleFunction = () => {
    setToggle(!toggle);
    window.localStorage.setItem("theme", String(!toggle));
    window.location.reload();
  };
  const Dark = React.lazy(() => import("../layouts/Dark"));
  const Light = React.lazy(() => import("../layouts/Light"));

  return (
    <ThemeContext.Provider value={{ toggle, toggleFunction }}>
      <Suspense fallback={<Spin spinning></Spin>}>
        {toggle ? <Dark>{children}</Dark> : <Light>{children}</Light>}
      </Suspense>
    </ThemeContext.Provider>
  );
};
export { ThemeContext, ThemeProvider };
