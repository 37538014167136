import axios from "axios";
import { API_URL } from ".";
import { Collection, CollectionMeta } from "../models/collection";
import {
  CollectionItem,
  CollectionItemSortOptions,
} from "../models/collectionItem";

export async function fetch({
  pageNumber,
  pageSize,
  query,
  sort,
}: {
  pageNumber: string;
  pageSize: string;
  query?: string;
  sort?: string;
}): Promise<{
  data: Collection[];
  meta: CollectionMeta;
}> {
  const response = await axios.get(`${API_URL}/top_collections/`, {
    params: {
      "page[number]": pageNumber,
      "page[size]": pageSize,
      "q[title_cont]": query,
      "q[m]": "or",
      sort: sort || "top_collection_rank asc",
    },
  });
  return response.data;
}

export async function items({
  collectionId,
  pageNumber,
  pageSize,
  sort = "score::asc",
}: {
  collectionId: string;
  pageNumber: string;
  pageSize: string;
  sort: CollectionItemSortOptions;
}): Promise<CollectionItem[]> {
  const response = await axios.get(`${API_URL}/collection_items/`, {
    params: {
      collection_id: collectionId,
      page: pageNumber,
      per_page: pageSize,
      sort,
    },
  });
  return response.data;
}

export async function get(id: string) {
  const response = await axios.get(`${API_URL}/top_collections/${id}`, {
    params: { collection_id: id },
  });
  return response.data;
}
