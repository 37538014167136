import { map } from "lodash";
import moment from "moment";
import { formatCurrencyNumber } from "../../utils/currency";
import useGetSnapshots from "../useGetSnapshots";

export const useAveragePriceSnapShot = (id: string) => {
  const { response, call, loading, interval } = useGetSnapshots(id);

  const data = map(response, (r) => ({
    avg_price: formatCurrencyNumber(r.resource_object.avg_price),
    market_cap:
      parseFloat(r.resource_object.floor_price_usd) *
      r.resource_object.total_cards,
    volume_usd: parseFloat(r.resource_object.volume_usd),
    floor_price: formatCurrencyNumber(r.resource_object.floor_price),
    created_at: moment(r.updated_at).format("DD-MM-YYYY HH:mm"),
  }));

  return { data, call, loading, interval };
};
