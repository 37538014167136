import axios from "axios";
import { API_URL } from ".";
import { Alert, AlertParams, AlertStatus } from "../models/alert";
export async function fetch(): Promise<{
  data: Alert[];
}> {
  const response = await axios.get(`${API_URL}/alerts/`, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
  return response.data;
}

export async function update(
  id: string,
  status: AlertStatus
): Promise<{
  data: Alert;
}> {
  const response = await axios.patch(
    `${API_URL}/alerts/${id}`,
    {
      data: {
        attributes: {
          status,
        },
      },
    },
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    }
  );
  return response.data;
}

export async function destroy(id: string): Promise<{
  data: Alert;
}> {
  const response = await axios.delete(`${API_URL}/alerts/${id}`, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
  return response.data;
}

export async function create(params: AlertParams): Promise<{
  data: Alert;
}> {
  const response = await axios.post(`${API_URL}/alerts/`, params, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
  return response.data;
}

export async function hasJoinedServer() {
  const response = await axios.get(`${API_URL}/has_joined_server/`, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
  return response.data;
}
