import { message } from "antd";
import { useState } from "react";

import { alertsApi } from "../api";
import { Alert, AlertParams } from "../models/alert";

export default function useCreateAlert() {
  const [response, setResponse] = useState<{
    data: Alert;
  }>();
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);

  const call = (params: AlertParams) => {
    setLoading(true);
    alertsApi
      .create(params)
      .then((response) => {
        setResponse(response);
        message.success("Alert created successfully");
      })
      .catch(async (err: any) => {
        setError(err?.response?.data?.error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return { response, error, loading, call };
}
