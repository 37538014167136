import { useEffect, useState } from "react";
import { snapshotsApi } from "../api";
import { Interval, Snapshot } from "../models/snapshot";

export default function useGetSnapshots(id: string) {
  const [response, setResponse] = useState<Snapshot[]>();
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState<boolean>(true);
  const [interval, setInterval] = useState<Interval>("30days");

  const call = (interval: Interval = "30days") => {
    setInterval(interval);
    setLoading(true);
    snapshotsApi
      .get(id, interval)
      .then((response) => {
        setResponse(response);
      })
      .catch(async (err) => {
        setError(err?.response?.data?.error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(call, [id]);

  return { response, error, loading, call, interval };
}
