import { useEffect, useState } from "react";
import { collectionsApi } from "../api";
import {
  CollectionItem,
  CollectionItemSortOptions,
} from "../models/collectionItem";

export default function useListCollectionItems(
  id: string,
  initialQuery?: {
    pageNumber: string;
    pageSize: string;
    sort: CollectionItemSortOptions;
  }
) {
  const [response, setResponse] = useState<CollectionItem[]>();
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState<boolean>(true);

  const call = ({
    pageNumber,
    pageSize,
    sort,
  }: {
    pageNumber: string;
    pageSize: string;
    sort: CollectionItemSortOptions;
  }) => {
    setLoading(true);
    collectionsApi
      .items({ collectionId: id, pageNumber, pageSize, sort })
      .then((response) => {
        setResponse(response);
      })
      .catch(async (err) => {
        setError(err?.response?.data?.error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    call(
      initialQuery || { pageNumber: "1", pageSize: "10", sort: "score::desc" }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { response, error, loading, call };
}
