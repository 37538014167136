import { map, uniqBy } from "lodash";
import moment from "moment";
import useGetSnapshots from "../useGetSnapshots";

export const useSaleSnapshot = (id: string) => {
  const { response, call, loading, interval } = useGetSnapshots(id);

  const listedData = uniqBy(
    map(response, (r) => ({
      id: r.resource_object.collection_id,
      state: "For Sale",
      created_at: moment(r.updated_at).format("DD-MM-YYYY HH:mm"),
      percentage:
        r.resource_object.total_cards_on_sale / r.resource_object.total_cards,
    })),
    "created_at"
  );

  const unlistedData = uniqBy(
    map(response, (r) => ({
      id: r.resource_object.collection_id,
      state: "Not For Sale",
      created_at: moment(r.updated_at).format("DD-MM-YYYY HH:mm"),
      percentage:
        (r.resource_object.total_cards -
          r.resource_object.total_cards_on_sale) /
        r.resource_object.total_cards,
    })),
    "created_at"
  );

  return { data: listedData.concat(unlistedData), call, loading, interval };
};
