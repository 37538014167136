import { DollarCircleOutlined, InfoCircleOutlined } from "@ant-design/icons";
import {
  Avatar,
  Button,
  Card,
  Image,
  Popover,
  Skeleton,
  Spin,
  Table,
  TablePaginationConfig,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import { truncate } from "lodash";
import { useParams } from "react-router-dom";
import { secondaryLinkClasses } from "../../constants";

import useListCollectionItems from "../../hooks/useListCollectionItems";
import { Collection } from "../../models/collection";
import {
  CollectionItem,
  CollectionItemSortOptions,
} from "../../models/collectionItem";
import { formatCurrency, numberWithCommas } from "../../utils/currency";

const Cards = ({ collection }: { collection: Collection }) => {
  let { id } = useParams();
  const { response: cards, loading, call } = useListCollectionItems(id!);

  const columns: any[] = [
    {
      title: "#",
      dataIndex: "token_series_id",
      key: "number",
      width: 100,
      fixed: "left",
      render: (id: string, record: CollectionItem) => (
        <div className="w-30 flex justify-center items-center">
          <Popover
            content={
              <Image
                title={id}
                src={record.image_url + "?w=100"}
                width={100}
                preview={false}
                placeholder={<Spin spinning />}
              />
            }
          >
            <Avatar size="large" src={record.image_url + "?w=100"} />
          </Popover>
        </div>
      ),
    },
    {
      title: () => (
        <Tooltip title="Official name of a card" className="cursor-pointer">
          Name <InfoCircleOutlined />
        </Tooltip>
      ),
      dataIndex: ["metadata", "table", "title"],
      width: 300,
      key: "name",
    },
    {
      title: () => (
        <Tooltip title="The card’s calculated rarity as per Paras' rarity calculation method.">
          Rarity <InfoCircleOutlined />
        </Tooltip>
      ),
      dataIndex: "score",
      key: "score",
      sorter: true,
      defaultSortOrder: "descend",
      render: (score: number) => score && score.toFixed(2),
    },
    {
      title: () => (
        <Tooltip title="The current price available for sale for a card">
          Listed Price <InfoCircleOutlined />
        </Tooltip>
      ),
      dataIndex: "price",
      key: "price",
      sorter: true,
      render: (price: number) => (price ? formatCurrency(price) : "-"),
    },
    {
      title: () => (
        <Tooltip title="Current owner of a card" className="cursor-pointer">
          Owner <InfoCircleOutlined />
        </Tooltip>
      ),
      dataIndex: "owner_id",
      key: "owner_id",
      render: (owner_id: string) =>
        owner_id && (
          <a
            href={`https://paras.id/${owner_id}/collectibles`}
            target="_blank"
            rel="noreferrer"
          >
            <Tag color="purple" className="cursor-pointer">
              {owner_id.endsWith(".near")
                ? truncate(owner_id, { length: 25 })
                : truncate(owner_id, { length: 10 })}
            </Tag>
          </a>
        ),
    },
    {
      title: "Actions",
      key: "actions",
      render: (record: CollectionItem) => (
        <Tooltip title="Click to go directly to card's page on Paras">
          <Button
            type="link"
            className={secondaryLinkClasses}
            href={`https://paras.id/token/${record.collection_item_id}`}
            target="_blank"
            icon={<DollarCircleOutlined />}
          >
            Buy on Paras
          </Button>
        </Tooltip>
      ),
    },
  ];

  const handleOnChange = (
    pagination: TablePaginationConfig,
    _filters: any,
    sorter: any
  ) => {
    const sort =
      sorter.field && sorter.order
        ? `${sorter.field}::${sorter.order.replace("end", "")}`
        : "score::desc";
    call({
      pageNumber: `${pagination.current}`,
      pageSize: `${pagination.pageSize}`,
      sort: sort as CollectionItemSortOptions,
    });
  };

  const CardsCount = () => {
    if (loading)
      return (
        <div className="w-60 pb-4">
          <Skeleton loading={loading} paragraph={false} />
        </div>
      );
    return (
      <Typography.Title level={4}>
        {numberWithCommas(`${collection?.attributes.total_cards ?? 0}`, 0)}{" "}
        Cards
      </Typography.Title>
    );
  };

  return (
    <>
      <CardsCount />
      <Card bodyStyle={{ padding: "0.1rem" }}>
        <Table
          showSorterTooltip={false}
          size="small"
          rowKey="collection_item_id"
          loading={loading}
          dataSource={cards}
          columns={columns}
          scroll={{ x: 1300 }}
          rowClassName="cursor-pointer"
          sortDirections={["descend", "ascend", "descend"]}
          onChange={handleOnChange}
          pagination={{
            position: ["bottomCenter"],
            total: collection?.attributes.total_cards ?? 0,
          }}
        />
      </Card>
    </>
  );
};

export default Cards;
