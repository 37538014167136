import { InfoCircleOutlined, SettingOutlined } from "@ant-design/icons";
import { Area, Column, Line, Scatter } from "@ant-design/plots";
import { Button, Card, Col, Dropdown, Menu, Row, Tooltip } from "antd";
import { isEmpty, minBy } from "lodash";
import { useParams } from "react-router-dom";
import { INTERVAL_MAP } from "../../constants";
import { useAveragePriceSnapShot } from "../../hooks/snapshots/useAveragePriceSnapShot";
import { usePriceRaritySnapshot } from "../../hooks/snapshots/usePriceRaritySnapshot";
import { useSaleSnapshot } from "../../hooks/snapshots/useSaleSnapshot";
import useGetCollection from "../../hooks/useGetCollection";
import { Interval } from "../../models/snapshot";
import { numberWithCommas } from "../../utils/currency";
import Owners from "./Owners";

const Analytics = () => {
  const { id } = useParams();
  const snapshot = useAveragePriceSnapShot(id!);
  const sale = useSaleSnapshot(id!);
  const priceRarity = usePriceRaritySnapshot(id!);
  const { response: collection } = useGetCollection(id!);

  const dataBar = [
    {
      price: "5-10",
      count: 38,
    },
    {
      price: "10-20",
      count: 52,
    },
    {
      price: "20-30",
      count: 61,
    },
    {
      price: "30-40",
      count: 145,
    },
  ];

  const configBar = {
    data: dataBar,
    xField: "label",
    yField: "value",
    height: 250,
    columnStyle: {
      fill: "#9351D6",
    },
  };

  const config = {
    animation: undefined,
    xField: "created_at",
    height: 250,
    xAxis: {
      tickCount: 24,
    },
    theme: {
      defaultColor: "#9351D6",
    },
    lineStyle: {
      stroke: "#9351D6",
    },
  };

  const configScatter = {
    appendPadding: 5,
    xField: "price",
    yField: "rarity",
    shape: "circle",
    colorField: "title",
    height: 250,
    yAxis: {
      line: {
        style: {
          stroke: "#9351D6",
        },
      },
    },
    xAxis: {
      label: {
        formatter: (value: any) => {
          return numberWithCommas(value.toString());
        },
      },
      grid: {
        line: {
          style: {
            stroke: "#9351D6",
          },
        },
      },
      line: {
        style: {
          stroke: "#9351D6",
        },
      },
    },
  };

  const configArea = {
    xField: "created_at",
    yField: "percentage",
    seriesField: "state",
    color: ["#9351D6", "#CBA3DF"],
    isPercent: true,
    height: 250,
    areaStyle: {
      fillOpacity: 0.9,
    },
    yAxis: {
      label: {
        formatter: (value: any) => {
          return value * 100;
        },
      },
    },
  };

  const menu = ({
    handleSelect,
  }: {
    handleSelect: ({ key }: { key: string }) => void;
  }) => (
    <Menu onClick={handleSelect}>
      <Menu.Item key="24hours">24H</Menu.Item>
      <Menu.Item key="7days">7D</Menu.Item>
      <Menu.Item key="30days">30D</Menu.Item>
    </Menu>
  );

  return (
    <>
      <Row className="w-full pb-6" justify="space-between" gutter={[10, 10]}>
        <Col xl={12} xs={24}>
          <Card
            loading={snapshot.loading}
            title={"Floor Price Ⓝ"}
            extra={
              <Dropdown
                overlay={menu({
                  handleSelect: ({ key }) => snapshot.call(key as Interval),
                })}
                placement="bottomCenter"
              >
                <Button type="link" icon={<SettingOutlined />}>
                  {INTERVAL_MAP[snapshot.interval]}
                </Button>
              </Dropdown>
            }
          >
            {snapshot.data && (
              <Line
                {...config}
                data={snapshot.data}
                xAxis={{
                  tickCount: snapshot.data.length,
                  label: {
                    formatter: (value: string) => {
                      return snapshot.interval.includes("days")
                        ? value.split(" ")[0]
                        : value.split(" ")[1];
                    },
                  },
                }}
                yAxis={{
                  label: {
                    formatter: (value: any) => {
                      return numberWithCommas(value.substring(0, 8));
                    },
                  },
                  min:
                    (minBy(snapshot.data, "floor_price")?.floor_price || 0) *
                    0.99,
                }}
                yField="floor_price"
              />
            )}
          </Card>
        </Col>

        <Col xl={12} xs={24}>
          <Card
            loading={snapshot.loading}
            title={"Average Price Ⓝ"}
            extra={
              <Dropdown
                overlay={menu({
                  handleSelect: ({ key }) => snapshot.call(key as Interval),
                })}
                placement="bottomCenter"
              >
                <Button type="link" icon={<SettingOutlined />}>
                  {INTERVAL_MAP[snapshot.interval]}
                </Button>
              </Dropdown>
            }
          >
            {snapshot.data && (
              <Line
                {...config}
                data={snapshot.data}
                xAxis={{
                  tickCount: snapshot.data.length,
                  label: {
                    formatter: (value: string) => {
                      return snapshot.interval.includes("days")
                        ? value.split(" ")[0]
                        : value.split(" ")[1];
                    },
                  },
                }}
                yAxis={{
                  label: {
                    formatter: (value: any) => {
                      return numberWithCommas(value.substring(0, 8));
                    },
                  },
                  min:
                    (minBy(snapshot.data, "avg_price")?.avg_price || 0) * 0.99,
                }}
                yField="avg_price"
              />
            )}
          </Card>
        </Col>

        <Col xl={12} xs={24}>
          <Card
            loading={snapshot.loading}
            title="Volume $"
            extra={
              <Dropdown
                overlay={menu({
                  handleSelect: ({ key }) => snapshot.call(key as Interval),
                })}
                placement="bottomCenter"
              >
                <Button type="link" icon={<SettingOutlined />}>
                  {INTERVAL_MAP[snapshot.interval]}
                </Button>
              </Dropdown>
            }
          >
            <Line
              {...config}
              data={snapshot.data}
              xAxis={{
                tickCount: snapshot.data.length,
                label: {
                  formatter: (value: string) => {
                    return snapshot.interval.includes("days")
                      ? value.split(" ")[0]
                      : value.split(" ")[1];
                  },
                },
              }}
              yAxis={{
                label: {
                  formatter: (value: any) => {
                    return numberWithCommas(value.toString());
                  },
                },
                min:
                  (minBy(snapshot.data, "volume_usd")?.volume_usd || 0) * 0.99,
              }}
              yField="volume_usd"
            />
          </Card>
        </Col>

        <Col xl={12} xs={24}>
          <Card
            loading={snapshot.loading}
            title="Market Cap $"
            extra={
              <Dropdown
                overlay={menu({
                  handleSelect: ({ key }) => snapshot.call(key as Interval),
                })}
                placement="bottomCenter"
              >
                <Button type="link" icon={<SettingOutlined />}>
                  {INTERVAL_MAP[snapshot.interval]}
                </Button>
              </Dropdown>
            }
          >
            <Line
              {...config}
              data={snapshot.data}
              xAxis={{
                tickCount: snapshot.data.length,
                label: {
                  formatter: (value: string) => {
                    return snapshot.interval.includes("days")
                      ? value.split(" ")[0]
                      : value.split(" ")[1];
                  },
                },
              }}
              yAxis={{
                label: {
                  formatter: (value: any) => {
                    return numberWithCommas(value.toString());
                  },
                },
                min:
                  (minBy(snapshot.data, "market_cap")?.market_cap || 0) * 0.99,
              }}
              yField="market_cap"
            />
          </Card>
        </Col>
      </Row>
      <Row className="pb-6">
        <Col span={24}>
          <Card
            loading={priceRarity.loading}
            title={
              <Row align="middle">
                Price Rarity &nbsp;
                <Tooltip title="Rarity is based on Paras' rarity score. Listed price is based on the collection's last refresh">
                  <InfoCircleOutlined />
                </Tooltip>
              </Row>
            }
          >
            {priceRarity.data && (
              <Scatter {...configScatter} data={priceRarity.data} />
            )}
          </Card>
        </Col>
      </Row>
      <Row className="pb-6">
        <Col span={24}>
          <Card
            loading={isEmpty(collection?.attributes.price_distribution)}
            title={
              <Row align="middle">
                Price Distribution &nbsp;
                <Tooltip title="For Sale">
                  <InfoCircleOutlined />
                </Tooltip>
              </Row>
            }
          >
            {collection?.attributes.price_distribution && (
              <>
                <span className="text-xs text-gray-500"># of Tokens</span>
                <Column
                  {...configBar}
                  data={collection?.attributes.price_distribution}
                />
                <div className="flex justify-center">
                  <span className="text-xs text-gray-500">Price Ranges</span>
                </div>
              </>
            )}
          </Card>
        </Col>
      </Row>

      <Row className="pb-6">
        <Col span={24}>
          <Card
            loading={sale.loading}
            title="For Sale vs. Not For Sale"
            extra={
              <Dropdown
                overlay={menu({
                  handleSelect: ({ key }) => sale.call(key as Interval),
                })}
                placement="bottomCenter"
              >
                <Button type="link" icon={<SettingOutlined />}>
                  {INTERVAL_MAP[sale.interval]}
                </Button>
              </Dropdown>
            }
          >
            <Area
              {...configArea}
              xAxis={{
                tickCount: sale.data.length,
              }}
              data={sale.data}
            />
          </Card>
        </Col>
      </Row>

      <Owners />
    </>
  );
};

export default Analytics;
