import axios from "axios";
import { API_URL } from ".";
import { Interval, Snapshot } from "../models/snapshot";

export async function get(id: string, interval: Interval): Promise<Snapshot[]> {
  const response = await axios.get(`${API_URL}/snapshots`, {
    params: { collection_id: id, interval },
  });

  return response.data;
}
